<template>
  <div class="ma-10">
    <v-form>
      <v-row>
        <v-col cols="12">
          <form-step-title title="Impact sur l'hébergement" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <form-fields-textarea
            v-model="strategie_long_terme_impact_capacites_ressources"
            field-label="Quel est l'impact de votre stratégie à 5 ans sur vos capacités et ressources d'hébergement ?" 
            placeholder="Quel est l'impact de votre stratégie à 5 ans sur vos capacités et ressources d'hébergement ?"
            class="mt-3"
            @change="saveFormAnswer"
          />
          <form-fields-textarea
            v-model="strategie_long_terme_impact_besoin_hebergement"
            field-label="Comment vos besoins d'hébergement seront-ils impactés ?" 
            placeholder="Comment vos besoins d'hébergement seront-ils impactés ?"
            @change="saveFormAnswer"
          />
          <form-fields-textarea
            v-model="strategie_long_terme_impact_offre_hebergement"
            field-label="Comment vos offres d'hébergement seront-elles impactées ?" 
            placeholder="Comment vos offres d'hébergement seront-elles impactées ?"
            @change="saveFormAnswer"
          />
          <form-fields-textarea
            v-model="commentaire_strategie"
            field-label="Commentaires. A quelle question auriez-vous souhaité répondre ? Quel(s) élément(s) souhaitez-vous porter à notre attention ?" 
            placeholder="A quelle question auriez-vous souhaité répondre ? Quel(s) élément(s) souhaitez-vous porter à notre attention ?"
            hint="Ceci est la dernière question."
            persistent-hint
            @change="saveFormAnswer"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import FormStepTitle from '@/components/FormStepTitle.vue'
import FormFieldsTextarea from '@/components/FormFieldsTextarea.vue'
import { mapActions, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: "LongTermeStrategy",
  components: {
    FormStepTitle,
    FormFieldsTextarea
  },

  data () {
    return {
      capacityImpact: "",
      needsImpact: "",
      offersImpact: "",
      comment: ""
    }
  },
  computed: {
    ...mapFields('form', [
      'formAnswer.strategie_long_terme_impact_capacites_ressources',
      'formAnswer.strategie_long_terme_impact_besoin_hebergement',
      'formAnswer.strategie_long_terme_impact_offre_hebergement',
      'formAnswer.commentaire_strategie',
    ]),
  },
  created() {
    this.fetchFormAnswer();
    this.setNextView('/dashboard');
  },
  methods: {
    ...mapActions('form', ['fetchFormAnswer', 'saveFormAnswer']),
    ...mapMutations('stepper', ['setNextView']),
  },
}
</script>